@import './constants.scss';

html,
body,
#root {
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: a-otf-gothic-bbb-pr6n, sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 16px;
}

.link {
    display: inline-block;
    cursor: pointer;

    &:hover {
        text-decoration: underline $white-color solid;
    }
}

.page-box {
    width: 100%;
    height: 100%;
    overflow: auto;
}

.black-background {
    background-color: $black-color;
}

.white-background {
    background-color: $white-color;
}

.pink-background {
    background-color: $pink-color;
}

.blue-background {
    background-color: $blue-color;
}

.orange-background {
    background-color: $orange-color;
}

.green-background {
    background-color: $green-color;
}


.accent-pink-background {
    background-color: $accent-pink;
}

.accent-blue-background {
    background-color: $accent-blue;
}

.accent-orange-background {
    background-color: $accent-orange;
}

.accent-green-background {
    background-color: $accent-green;
}


.deep-pink-background {
    background-color: $deep-pink;
}

.deep-blue-background {
    background-color: $deep-blue;
}

.deep-orange-background {
    background-color: $deep-orange;
}

.deep-green-background {
    background-color: $deep-green;
}

.light-pink-background {
    background-color: $light-pink;
}

.light-blue-background {
    background-color: $light-blue;
}

.light-orange-background {
    background-color: $light-orange;
}

.light-green-background {
    background-color: $light-green;
}

.black {
    color: $black-color;
}

.white {
    color: $white-color;
}

.pink {
    color: $pink-color;
}

.blue {
    color: $blue-color;
}

.orange {
    color: $orange-color;
}

.green {
    color: $green-color;
}


.accent-pink {
    color: $accent-pink;
}

.accent-blue {
    color: $accent-blue;
}

.accent-orange {
    color: $accent-orange;
}

.accent-green {
    color: $accent-green;
}

.deep-pink {
    color: $deep-pink;
}

.deep-blue {
    color: $deep-blue;
}

.deep-orange {
    color: $deep-orange;
}

.deep-green {
    color: $deep-green;
}

.light-pink {
    color: $light-pink;
}

.light-blue {
    color: $light-blue;
}

.light-orange {
    color: $light-orange;
}

.light-green {
    color: $light-green;
}

.maximum-expand {
    width: 100%;
    height: 100%;
}
