@import '../../constants.scss';

.umashiba-page-card {
    padding: 10px;

    .umashiba-page-card-content-container {
        padding: 10px 10px 20px 20px;
    }

}
