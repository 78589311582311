@keyframes bgslide-left-to-right {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(100%);
    }
}

@keyframes show-line {
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes fadein {
    to {
        opacity: 1;
    }
}


.home-h1-container {
    overflow: hidden;

    svg {

        text:first-child {
            animation-name: show-line;
            animation-duration: 3s;
            animation-timing-function: ease;
            animation-fill-mode: forwards;
            stroke: #ffffff;
            stroke-width: 1px;
            stroke-dasharray: 1000;
            stroke-dashoffset: 1000;
            fill: none;
        }

        text:nth-child(2) {
            animation-name: fadein;
            animation-duration: 0.6s;
            animation-timing-function: ease;
            animation-fill-mode: forwards;
            fill: #ffffff;
            opacity: 0;
        }
    }

    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 101%;
        height: 100%;
        background-color: #ffffff;
        transform: translateX(-100%);
        animation-name: bgslide-left-to-right;
        animation-duration: 1s;
        animation-timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
        animation-fill-mode: forwards;
    }

}

.home-h1-container-no-animation {
    overflow: hidden;

    svg {
        text:first-child {
            fill: #ffffff;
            stroke: #ffffff;
            stroke-width: 1px;
            stroke-dasharray: 1000;
        }
    }
}
