@import '../constants.scss';

.color-page-top-space {
    height: 50px;
    width: calc(100% - 28px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 14px 14px 10px 14px;
}
