@import '../constants.scss';

@keyframes inset-shadow-fadein {
    from {
        box-shadow: inset 0px 6px 6px rgba(0, 0, 0, 0);
    }

    to {
        box-shadow: inset 0px 6px 6px rgba(0, 0, 0, 0.5);
    }
}

@keyframes normal-shadow-fadein {
    from {
        box-shadow: 0px -6px 6px rgba(0, 0, 0, 0);
    }

    to {
        box-shadow: 0px -6px 6px rgba(0, 0, 0, 0.5);
    }
}

#colorful-background {
    width: 100%;
    overflow-x: hidden;
}

.home-area {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 25vh;
    width: 100%;
}

.home-area-cursor {
    cursor: pointer;
}

.inset-shadow {
    animation: inset-shadow-fadein 0.7s forwards;
}

.normal-shadow {
    animation: normal-shadow-fadein 0.7s forwards;
}
