@import '../constants.scss';

@keyframes HideUMASHIBAPortArea {
    to {
        width: 100%;
    }
}

@keyframes UMASHIBAPortToWhiteScreen {
    to {
        width: 0;
    }
}

.umashiba-port-area-animation {
    z-index: 110;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 100%;

    div {
        height: 10%;
    }
}

.umashiba-port-area-hide-animation {
    align-items: flex-end;

    div {
        width: 0%;

        &:nth-child(1) {
            animation: HideUMASHIBAPortArea 200ms forwards 50ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
        }

        &:nth-child(2) {
            animation: HideUMASHIBAPortArea 200ms forwards 100ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
        }

        &:nth-child(3) {
            animation: HideUMASHIBAPortArea 200ms forwards 150ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
        }

        &:nth-child(4) {
            animation: HideUMASHIBAPortArea 200ms forwards 200ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
        }

        &:nth-child(5) {
            animation: HideUMASHIBAPortArea 200ms forwards 250ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
        }

        &:nth-child(6) {
            animation: HideUMASHIBAPortArea 200ms forwards 300ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
        }

        &:nth-child(7) {
            animation: HideUMASHIBAPortArea 200ms forwards 350ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
        }

        &:nth-child(8) {
            animation: HideUMASHIBAPortArea 200ms forwards 400ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
        }

        &:nth-child(9) {
            animation: HideUMASHIBAPortArea 200ms forwards 450ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
        }

        &:nth-child(10) {
            animation: HideUMASHIBAPortArea 200ms forwards 500ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
        }
    }
}

.umashiba-port-area-to-white-screen-animation {
    align-items: flex-start;

    div {
        width: 100%;
        z-index: 112;

        &:nth-child(1) {
            animation: UMASHIBAPortToWhiteScreen 200ms forwards 50ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
        }

        &:nth-child(2) {
            animation: UMASHIBAPortToWhiteScreen 200ms forwards 100ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
        }

        &:nth-child(3) {
            animation: UMASHIBAPortToWhiteScreen 200ms forwards 150ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
        }

        &:nth-child(4) {
            animation: UMASHIBAPortToWhiteScreen 200ms forwards 200ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
        }

        &:nth-child(5) {
            animation: UMASHIBAPortToWhiteScreen 200ms forwards 250ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
        }

        &:nth-child(6) {
            animation: UMASHIBAPortToWhiteScreen 200ms forwards 300ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
        }

        &:nth-child(7) {
            animation: UMASHIBAPortToWhiteScreen 200ms forwards 350ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
        }

        &:nth-child(8) {
            animation: UMASHIBAPortToWhiteScreen 200ms forwards 400ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
        }

        &:nth-child(9) {
            animation: UMASHIBAPortToWhiteScreen 200ms forwards 450ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
        }

        &:nth-child(10) {
            animation: UMASHIBAPortToWhiteScreen 200ms forwards 500ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
        }

        &:last-child {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 111;
            width: 100%;
            height: 100%;

        }
    }
}
