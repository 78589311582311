@import '../constants.scss';
@import './homeConstants.scss';

#skills-area {
    z-index: 101;
    background-color: $orange-color;

    div:first-of-type {
        position: absolute;
        left: -60%;
        top: -8%;
        width: 200%;
        height: 30%;
        background-color: $orange-color;
        transform: rotate(-5deg);
    }
}

.skills-home-h1 {
    position: absolute;
    top: 30%;
    left: 20%;

    svg {
        text {
            &:first-child {
                animation-delay: $homeAreaTextAnimationStartDelay + 1.6;
            }

            &:nth-child(2) {
                animation-delay: $homeAreaTextAnimationStartDelay + 2.2;
            }
        }
    }

    &:before {
        animation-delay: $homeAreaTextCoverAnimationStartDelay + 1.6;
    }
}
