$colorPageAppearAnimationDuration: 400ms;

.color-page-appear-animation {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    overflow: hidden;

    div {
        transform: translateY(-100%);
        width: 10%;
        height: calc(100% + 10vw);
        border-radius: 0 0 10vw 10vw;

        &:nth-child(1) {
            animation: expandHeight $colorPageAppearAnimationDuration forwards 400ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
        }

        &:nth-child(2) {
            animation: expandHeight $colorPageAppearAnimationDuration forwards 300ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
        }

        &:nth-child(3) {
            animation: expandHeight $colorPageAppearAnimationDuration forwards 400ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
        }

        &:nth-child(4) {
            animation: expandHeight $colorPageAppearAnimationDuration forwards 500ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
        }

        &:nth-child(5) {
            animation: expandHeight $colorPageAppearAnimationDuration forwards 400ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
        }

        &:nth-child(6) {
            animation: expandHeight $colorPageAppearAnimationDuration forwards 300ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
        }

        &:nth-child(7) {
            animation: expandHeight $colorPageAppearAnimationDuration forwards 400ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
        }

        &:nth-child(8) {
            animation: expandHeight $colorPageAppearAnimationDuration forwards 300ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
        }

        &:nth-child(9) {
            animation: expandHeight $colorPageAppearAnimationDuration forwards 500ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
        }

        &:nth-child(10) {
            animation: expandHeight $colorPageAppearAnimationDuration forwards 400ms cubic-bezier(0.46, 0.03, 0.52, 0.96);
        }
    }
}
