@import '../constants.scss';
@import './homeConstants.scss';

#works-area {
    z-index: 101;
    background-color: $blue-color;

    div:first-child {
        position: absolute;
        left: -60%;
        top: -12%;
        width: 200%;
        height: 30%;
        background-color: $blue-color;
        transform: rotate(5deg);
    }
}

.works-home-h1 {
    position: absolute;
    top: 30%;
    right: 20%;


    svg {
        text {
            &:first-child {
                animation-delay: $homeAreaTextAnimationStartDelay + 0.8;
            }

            &:nth-child(2) {
                animation-delay: $homeAreaTextAnimationStartDelay + 1.6;
            }
        }
    }

    &:before {
        animation-delay: $homeAreaTextCoverAnimationStartDelay + 0.8;
    }

}
