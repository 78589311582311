@import '../constants.scss';

@keyframes colorPageCloseButtonTopToBottom {
    from {
        transform: translateY(-100px);
    }

    to {
        transform: translateY(0);
    }
}

@keyframes colorPageCloseButtonBottomToTop {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(-100px);
    }
}

.color-page-close-button {
    width: 80px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
    transition: 0.2s;

    @media (min-width: $sm-breakpoint) {
        width: 100px;
        height: 44px;
        border-radius: 22px;
    }

    span {
        margin-bottom: 3px;
    }
}

.color-page-close-button-show {
    transform: translateY(-100px);
    animation: colorPageCloseButtonTopToBottom 500ms forwards 600ms;
}

.color-page-close-button-hide {
    transform: translateY(0);
    animation: colorPageCloseButtonBottomToTop 500ms forwards;
}
