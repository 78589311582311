@import '../constants.scss';


@keyframes colorPageTitleExpandTitle {
    from {
        transform: scale(0.05, 0.05);
    }

    to {
        transform: scale(1, 1);
    }
}

@keyframes colorPageTitleMinimumTitle {
    from {
        transform: scale(1, 1);
    }

    to {
        transform: scale(0, 0);
    }
}

.color-page-title {
    width: 146px;
    height: 40px;
    border-radius: 7px;
    box-shadow: 4px 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    background-color: $white-color;

    @media (min-width: $sm-breakpoint) {
        font-size: 24px;
        width: 160px;
        height: 44px;
        box-shadow: 4px 4px;
    }

    span {
        margin-bottom: 4px;

        @media (min-width: $sm-breakpoint) {
            margin-bottom: 3px;
        }
    }
}

.expand-color-page-title {
    transform: scale(0.05, 0.05);
    animation: colorPageTitleExpandTitle 500ms forwards;
}

.minimum-color-page-title {
    transform: scale(1, 1);
    animation: colorPageTitleMinimumTitle 500ms forwards 300ms;
}
