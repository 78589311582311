$black-color: #000000;
$white-color: #FFFFFF;
$pink-color: #E4007F;
$blue-color: #00A0E9;
$orange-color: #F39800;
$green-color: #8fc31f;

$accent-pink: #CC007F;
$accent-blue: #0D95D4;
$accent-orange: #F37F00;
$accent-green: #8FB21F;

$deep-pink: #9F0063;
$deep-blue: #167AAD;
$deep-orange: #C6690A;
$deep-green: #7D9B21;

$light-pink: lighten($pink-color, 40%);
$light-blue: lighten($blue-color, 40%);
$light-orange: lighten($orange-color, 40%);
$light-green: lighten($green-color, 40%);

$sm-breakpoint: 897px;
