@import "../constants.scss";

.color-page-content-space {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  overflow-y: auto;

  @media (min-width: $sm-breakpoint) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
  }
}
