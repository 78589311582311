@import '../constants.scss';
@import './homeConstants.scss';

#contact-area {
    z-index: 102;
    background-color: $green-color;

    div:first-child {
        position: absolute;
        left: -60%;
        top: -10%;
        width: 200%;
        height: 30%;
        background-color: $green-color;
        transform: rotate(5deg);
    }
}

.contact-home-h1 {
    position: absolute;
    top: 40%;
    right: 20%;

    svg {
        text {
            &:first-child {
                animation-delay: $homeAreaTextAnimationStartDelay + 2.4s;
            }

            &:nth-child(2) {
                animation-delay: $homeAreaTextAnimationStartDelay + 3;
            }
        }
    }

    &:before {
        animation-delay: $homeAreaTextCoverAnimationStartDelay + 2.4;
    }
}
