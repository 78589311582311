@import '../constants.scss';

@keyframes expandHeight {
    to {
        transform: translateY(0);
    }
}

@keyframes colorPageSpaceMinimumWidth {
    to {
        width: 0;
    }
}

.color-page-display-flex {
    display: flex;
}

.color-page-display-none {
    display: none;
}

.color-page-space {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: auto;
    animation: showPage 100ms forwards 2000ms;
}
