@import '../constants.scss';
@import './homeConstants.scss';

#umashiba-port-area {
    z-index: 100;
    background-color: $pink-color;
}

.umashiba-port-home-h1 {
    position: absolute;
    top: 30%;
    left: 10%;

    svg {
        text {
            &:first-child {
                animation-delay: $homeAreaTextAnimationStartDelay;
            }

            &:nth-child(2) {
                animation-delay: $homeAreaTextAnimationStartDelay + 0.8;
            }
        }
    }

    &:before {
        animation-delay: $homeAreaTextCoverAnimationStartDelay;
    }
}
