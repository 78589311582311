@import '../constants.scss';

@keyframes tagPageTitleExpandTitle {
    from {
        transform: scale(0.05, 0.05);
    }

    to {
        transform: scale(1, 1);
    }
}

@keyframes tagPageTitleMinimumTitle {
    from {
        transform: scale(1, 1);
    }

    to {
        transform: scale(0, 0);
    }
}

.tag-page-title {
    min-width: 80px;
    height: 36px;
    padding: 4px 16px 0 16px;
    box-shadow: 2px 2px;
    border-radius: 20px;
    text-align: center;
    font-size: 20px;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    margin-bottom: 10px;

    span {
        color: $white-color;
        text-align: center;
    }
}

.expand-tag-page-title {
    transform: scale(0.05, 0.05);
    animation: tagPageTitleExpandTitle 500ms forwards;
}

.minimum-tag-page-title {
    transform: scale(1, 1);
    animation: tagPageTitleMinimumTitle 500ms forwards 300ms;
}
